import AbstractClass from "./abstract_class"

export default class ApiModel extends AbstractClass {
  constructor() {
    super("shimizu-toabo-carpet-defect-detection-api")
  }

  public async getCameraSetting(path: string, param: {}): Promise<void> {
    return this.get(path, {
      queryStringParameters: param
    })
  }
}
