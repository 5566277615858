


























































import Vue, { PropType } from "vue"
import router from "../router"
import { Auth } from "aws-amplify"
import { LocationRecord } from "@/types"

export default Vue.extend({
  name: "header-view",
  props: {
    // この値がメイン画面から呼ばれていることを指している時のみ、表示をメイン画面仕様にする
    pageId: String,
    // 場所一覧取得で得た内容はpropsで親コンポーネントから受け取る
    menuList: {
      type: Array as () => Array<LocationRecord>,
      default: undefined
    },
    selectedLocation: {
      type: Object as PropType<LocationRecord>,
      default: function() {
        return {}
      }
    }
  },
  data: () => ({
    // メニューの中で選択されている1件を指す変数
    selectedLocationOnHeader: {} as LocationRecord
  }),
  created() {
    this.debugLog("main header")
  },
  computed: {
    isDashboard() {
      return this.pageId === "dashboard"
    },
    isMaintenance() {
      return this.pageId === "maintenance"
    }
  },

  methods: {
    async signout(): Promise<void> {
      try {
        await Auth.signOut()
        router.push("/signin")
      } catch (err) {
        console.error(err)
      }
    },
    // コンソールにエラーが出るようになったため、空内容の関数定義を置かせて頂きます
    toggleMobileMenu() {
      return
    },
    onChangeSelectedLocation(selected: LocationRecord) {
      // 設備名変更時は変更後のlocation_idをローカルストレージに保存し、ページリロードする
      localStorage.setItem("location_id", selected.location_id)
      location.reload()
    },
    moveToMainPage() {
      router.push("/")
    },
    // デバッグ中のためのconsole.log()へのwrapper関数
    // ⇒リリース時は関数内をコメントアウトすること
    debugLog(arg0: any, ...args: any): void {
      // console.log(arg0, ...args)
    }
  },
  watch: {
    menuList: function() {
      if (this.menuList != null && this.menuList.length > 0) {
        // if: データが存在しない場合
        this.debugLog(
          "selectedLocationOnHeader:",
          this.selectedLocationOnHeader
        )
      } else {
        this.selectedLocationOnHeader = {} as LocationRecord
      }
    },
    selectedLocation: function() {
      if (this.selectedLocation) {
        this.selectedLocationOnHeader = JSON.parse(
          JSON.stringify(this.selectedLocation)
        )
      }
    }
  }
})
