import { API } from "aws-amplify"

export default class AbstractClass {
  private apiName: string

  constructor(apiName: string) {
    this.apiName = apiName
  }

  // Get method
  public async get(
    path: string,
    init: { [k: string]: any } = {}
  ): Promise<any> {
    return API.get(this.apiName, path, init)
  }

  // Post method
  public async post(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.post(this.apiName, path, init)
  }

  // Put method
  public async put(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.put(this.apiName, path, init)
  }

  // Delete method
  public async del(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.del(this.apiName, path, init)
  }
}
