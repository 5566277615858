


























































































































































































































































































import Vue from "vue"
import MainHeader from "@/components/MainHeader.vue"
import { DefaultProfile, ProfileData, ProfileValue } from "@/types"
import ApiModel from "@/apis/web_api"

export default Vue.extend({
  components: { MainHeader },
  data() {
    return {
      apiModal: null,
      dialog: false,
      dialogDelete: false,
      valid: false,
      typeList: [
        { type: "normal", type_text: "対象色範囲" },
        { type: "abnormal", type_text: "異物色範囲" }
      ],
      schemaList: ["type1"],
      headers: [
        {
          text: "プロファイル名",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "プロファイルタイプ", value: "typeObject" },
        { text: "検出フィルター", value: "schema" },
        { text: "プロファイル設定内容", value: "value" },
        { text: "アクション", value: "action" }
      ],
      profileList: [] as ProfileValue[],
      editedIndex: -1,
      editedItem: {
        profile_id: "",
        name: "",
        typeObject: { type: "", type_text: "" },
        schema: "",
        value: {
          min: {
            R: 0,
            G: 0,
            B: 0
          },
          max: {
            R: 0,
            G: 0,
            B: 0
          }
        },
        right_exclusion_mm: 0,
        left_exclusion_mm: 0
      } as DefaultProfile,
      defaultItem: {
        profile_id: "",
        name: "",
        typeObject: { type: "", type_text: "" },
        type_text: "",
        schema: "",
        value: {
          min: {
            R: 0,
            G: 0,
            B: 0
          },
          max: {
            R: 0,
            G: 0,
            B: 0
          }
        },
        right_exclusion_mm: 0,
        left_exclusion_mm: 0
      } as DefaultProfile,
      rules: {
        requiredString: (value: string) => !!value || "必須入力です",
        requiredNumber: (value: number) =>
          !!value || value === 0 || "必須入力です",
        number: (value: number) =>
          (!isNaN(value) && value >= 0) || "整数の数値を入力して下さい"
      },
      isLoading: false,
      isError: false,
      isErrorOfGetProfileList: false,
      isErrorOfCreateProfile: false,
      isErrorOfEditProfile: false,
      isErrorOfDeleteProfile: false
    }
  },
  async created() {
    this.apiModal = new ApiModel()
    this.isLoading = true
    await this.getAndSetProfileList()
    this.isLoading = false
  },
  computed: {
    formTitle(): string {
      return this.editedIndex === -1 ? "プロファイル追加" : "プロファイル編集"
    },
    rgbRules() {
      const errMessage = "有効範囲：0～255"
      const rules = [
        (v: number) => {
          if (v == null) {
            return true
          }
          return (v >= 0 && v <= 255) || errMessage
        },
        (v: any) => {
          return typeof v === "number" || errMessage
        }
      ]
      return rules
    },
    exclusionMmRules() {
      const errMessage = "有効範囲：0～100"
      const rules = [
        (v: number) => {
          if (v == null) {
            return true
          }
          return (v >= 0 && v <= 100) || errMessage
        },
        (v: any) => {
          return typeof v === "number" || errMessage
        }
      ]
      return rules
    }
  },
  methods: {
    async getAndSetProfileList() {
      const response = await this.apiModal
        .get("/profiles")
        .catch((error: any): undefined => {
          console.log(error.response.status, error.response.data)
          return undefined
        })
      if (response === undefined) {
        console.error("Failed to get the profile list")
        this.isError = true
        this.isErrorOfGetProfileList
      }

      const records: any[] = response.records
      records.forEach((value: ProfileData, index: number) => {
        const valueSetting = {
          min: {
            R: value.profile_value.min.R,
            G: value.profile_value.min.G,
            B: value.profile_value.min.B
          },
          max: {
            R: value.profile_value.max.R,
            G: value.profile_value.max.G,
            B: value.profile_value.max.B
          }
        }
        const profile = {
          profile_id: value.profile_id,
          name: value.profile_name,
          typeObject: {
            type: value.profile_type,
            type_text:
              value.profile_type === "normal" ? "対象色範囲" : "異物色範囲"
          },
          schema: value.camera_filter_schema,
          value: JSON.stringify(valueSetting),
          left_exclusion_mm: value.left_exclusion_mm,
          right_exclusion_mm: value.right_exclusion_mm
        }
        this.$set(this.profileList, index, profile)
      })
    },
    editItem(item: ProfileValue) {
      this.editedIndex = this.profileList.indexOf(item)
      const copyItem = item
      const profile = {
        profile_id: copyItem.profile_id,
        name: copyItem.name,
        typeObject: {
          type: copyItem.typeObject.type,
          type_text:
            copyItem.typeObject.type === "normal" ? "対象色範囲" : "異物色範囲"
        },
        schema: copyItem.schema,
        value: JSON.parse(copyItem.value),
        left_exclusion_mm: copyItem.left_exclusion_mm,
        right_exclusion_mm: copyItem.right_exclusion_mm
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, profile)
        this.dialog = true
      })
    },
    formatValue(value: string, schema: string) {
      const convertJsonValue = JSON.parse(value)
      if (schema === "type1")
        return `min:[R:${convertJsonValue.min.R},G:${convertJsonValue.min.G},B:${convertJsonValue.min.B}] / max:[R:${convertJsonValue.max.R},G:${convertJsonValue.max.G},B:${convertJsonValue.max.B}]`
    },
    formatType(type: string) {
      return type === "normal" ? "対象色範囲" : "異物色範囲"
    },

    deleteItem(item: ProfileValue) {
      const copyItem = item
      const profile = {
        profile_id: copyItem.profile_id,
        name: copyItem.name,
        typeObject: {
          type: copyItem.typeObject.type,
          type_text:
            copyItem.typeObject.type === "normal" ? "対象色範囲" : "異物色範囲"
        },
        schema: copyItem.schema,
        value: JSON.parse(copyItem.value),
        left_exclusion_mm: copyItem.left_exclusion_mm,
        right_exclusion_mm: copyItem.right_exclusion_mm
      }
      this.editedIndex = this.profileList.indexOf(copyItem)
      this.editedItem = Object.assign({}, profile)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.isLoading = true
      const response = await this.apiModal
        .del("/profile", {
          queryStringParameters: {
            profile_id: this.editedItem.profile_id
          }
        })
        .catch((error: any): undefined => {
          console.log(error.response.status, error.response.data)
          return undefined
        })
      if (response === undefined) {
        console.error("Failed to delete profile")
        this.isError = true
        this.isErrorOfDeleteProfile = true
      }
      // 最新のプロファイル一覧取得、データのセット
      await this.getAndSetProfileList()
      this.isLoading = false
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.isLoading = true
      const requestBody = {
        profile_id: this.editedItem.profile_id,
        profile_name: this.editedItem.name,
        left_exclusion_mm: this.editedItem.left_exclusion_mm,
        right_exclusion_mm: this.editedItem.right_exclusion_mm,
        camera_filter_schema: this.editedItem.schema,
        profile_type: this.editedItem.typeObject.type,
        profile_value: this.editedItem.value
      }

      if (this.editedIndex > -1) {
        // if: プロファイル更新の場合
        const response = await this.apiModal
          .put("/profile", {
            body: requestBody
          })
          .catch((error: any): undefined => {
            console.log(error.response.status, error.response.data)
            return undefined
          })
        if (response === undefined) {
          console.error("Profile update failed")
          this.isError = true
          this.isErrorOfEditProfile = true
        }
      } else {
        // if: プロファイル新規作成の場合
        // 新規作成ではprofile_idが不要なため削除する
        delete requestBody["profile_id"]
        const response = await this.apiModal
          .post("/profile", {
            body: requestBody
          })
          .catch((error: any): undefined => {
            console.log(error.response.status, error.response.data)
            return undefined
          })
        if (response === undefined) {
          console.error("Failed to create a new profile")
          this.isError = true
          this.isErrorOfCreateProfile = true
        }
      }
      // 最新のプロファイル一覧取得、データのセット
      await this.getAndSetProfileList()
      this.isLoading = false
      this.close()
    },
    async openDialog() {
      await (this.dialog = true)
      ;(this.$refs.profileForm as any).reset()
    },
    resetErrorFlag() {
      this.isError = false
      this.isErrorOfGetProfileList = false
      this.isErrorOfCreateProfile = false
      this.isErrorOfEditProfile = false
      this.isErrorOfDeleteProfile = false
    }
  }
})
