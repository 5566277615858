
















import Vue from "vue"
import router from "./router"
import { Auth } from "aws-amplify"
import { flagsNamespacedHelper } from "@/stores/flags"

const flagsMapState = flagsNamespacedHelper.mapState({
  overlay: (state: any) => state.overlay
})

export default Vue.extend({
  data() {
    return {}
  },
  computed: {
    ...flagsMapState,
    changeLinkButtonProps(): Function {
      const currentRoute: string = this.$route.path
      return (routeString: string, propText: string): string | boolean => {
        switch (propText) {
          case "text":
            return routeString !== currentRoute
          default:
            return routeString === currentRoute ? "indigo darken-3" : ""
        }
      }
    }
  },
  methods: {
    async signout(): Promise<void> {
      try {
        await Auth.signOut()
        router.push("/signin")
      } catch (err) {
        console.error(err)
      }
    },
    linkPage(linkPath: string): void {
      if (linkPath !== this.$route.path) {
        router.push(linkPath)
      }
    }
  }
})
