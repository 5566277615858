











import Vue from "vue"
export default Vue.extend({
  name: "login-header-view",
  data() {
    return {}
  }
})
