






import Vue from "vue"

export default Vue.extend({
  name: "display-image",
  data: () => ({
    // 画像URL
    imagePath: "" as string | string[],
    // ウィンドウの種別(別タブ／別ウィンドウ) ※結局使っていないが不具合解析用として残しておく
    windoStyle: "" as string | string[]
  }),
  created() {
    // console.log("display image:", this.$route, this.$router)
    console.log("display image:", this.$route.query)
    this.imagePath = this.$route.query.queryDisplayImage
    this.windoStyle = this.$route.query.queryWindoStyle
  }
})
