





























































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue"
import WebApiModel from "@/apis/web_api"
import MainHeader from "@/components/MainHeader.vue"
import router from "@/router"
import { flagsNamespacedHelper } from "@/stores/flags"
import { Auth } from "aws-amplify"
import {
  FilterSettings,
  LocationRecord,
  ProfileRecord,
  HistoryRecord
} from "@/types"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

// Vuex store helpers
const flagsMapActions = flagsNamespacedHelper.mapActions(["setOverlay"])

export interface SelectableHistoryRecord extends HistoryRecord {
  // テーブル内でハイライト表示するためのメンバー
  color?: string
}

export default Vue.extend({
  components: { MainHeader },
  data() {
    return {
      webApiModel: null,
      // UTCからのオフセット時間(分単位)を環境変数から取り込み
      utcOffsetMinutes: parseInt(process.env.VUE_APP_UTC_OFFSET_MINUTES),
      // 自動更新の間隔を環境変数から取り込み(秒単位の指定値を取り込んでミリ秒単位で保持)
      automaticUpdateIntervalMsec:
        parseInt(process.env.VUE_APP_AUTOMATIC_UPDATE_INTERVAL_SEC) * 1000,
      // 最新データ取得日時から指定時間以内にエラーが検出されているかを
      // チェックする際の秒数
      diffSecondsOfcheckRecordTimeOfErrorInHistories: parseInt(
        process.env.VUE_APP_CHECK_INTERVAL_SEC_FOR_RECENT_ERROR_IN_HISTORIES
      ),
      // タイマー設定用の変数
      timeOutId: 0,
      // カメラの稼働状態 ※createdにてAPIから取得するまで一旦trueにしておく
      isCameraRunning: true,
      // 選択している設備名のlocation_idを、検索処理用に保存しておくための変数
      locationId: "",
      // エラー画像選択モード
      pictureSelectMode: "auto",
      fromCalender: "",
      fromDate: "",
      fromFormatted: "",
      forApiFromFormatted: "",
      toFormatted: "",
      // 日付指定の入力欄が空の場合のエラーフラグ
      isErrorOfEmptyFromDate: false,
      // プロファイル切り替え設定の入力欄に誤りが1個以上ある場合のエラーフラグ
      isErrorOfInputChangeLocationProfile: false,
      // 手動選択の場合の検索でAPIがエラーを返した場合のフラグ
      isErrorInSearchHistoriesManually: false,
      // 最新エラー表示の場合の検索でAPIがエラーを返した場合のフラグ
      isErrorInSearchHistoriesAutomatic: false,
      // 当該ページへ遷移して来た時、または再読み込み操作をした時に、
      // 場所一覧取得APIがエラーを返した場合のフラグ
      isErrorInGetLocationsWhenCreated: false,
      // カメラアプリ判定ストップ⇔スタートを切り替えた時にAPIがエラーを返した場合のフラグ
      isErrorInCameraAppStartAndStop: false,
      // プロファイル切り替え設定のモーダルダイアログへ移行する際に
      // プロファイル一覧取得APIがエラーを返した場合のフラグ
      isErrorInPrepareDialogOfChangeLocationProfile: false,
      // プロファイル切り替え(場所ごと)APIがエラーを返した場合のフラグ
      isErrorInChangeLocationProfile: false,
      // 画像別タブ表示または画像別ウィンドウ表示のボタンを押下した際に
      // 異常検出履歴を未選択だった場合のフラグ
      isImgErrorNotSelectedCameraHistoryRecord: false,
      // 画像別タブ表示または画像別ウィンドウ表示のボタンを押下した際に
      // 異常検出履歴が0件だった場合のフラグ
      isImgErrorEmptyCameraHistory: false,
      // 最新データ取得日時から指定時間以内にエラーが検出されているかを
      // チェックした結果のフラグ
      foundRecentErrorInHistories: false,
      // 同エラー表示の文言
      // ※createdで値を指定するのでここでは仮の値で初期化しておく
      messageForFoundRecentErrorInHistories: "",
      // 同エラー表示で異常検出履歴の方かシステム情報履歴の方かの表記部分
      recordTypeOfFoundRecentErrorInHistories: "",
      // 同エラー表示の日時表記部分 ※検出した履歴情報のrecord_time
      recordTimeOfFoundRecentErrorInHistories: "",
      // エラー表示のスナックバーの文言
      recentErrorMessage: "",
      // ローディング表示用のフラグ群
      isLoadingOfGetLocations: false,
      isLoadingOfGetProfiles: false,
      isLoadingOfGetHistories: false,
      isLoadingOfCameraAppStartAndStop: false,
      isLoadingOfChangeLocationProfile: false,
      // プロファイル切り替え設定のモーダルダイアログを表示するフラグ
      showDialogOfChangeLocationProfile: false,
      isValidInputFormForChangeLocationProfile: false,
      selectedLocation: {} as LocationRecord,
      locations: [] as Array<LocationRecord>,
      selectedProfile: {} as ProfileRecord,
      currentFilterSettings: {} as FilterSettings,
      // XXX: 両端除外設定は変数上はmmとなっているが実際はパーセントなので注意！！
      currentLeftExclusionMm: 0,
      // XXX: 両端除外設定は変数上はmmとなっているが実際はパーセントなので注意！！
      currentRightExclusionMm: 0,
      currentFilterType: "",
      profiles: [] as Array<ProfileRecord>,
      lastUpdateTime: "",
      historyHeader: [
        { text: "日時", value: "record_time" },
        { text: "内容", value: "message" }
      ],
      cameraHistory: [] as Array<SelectableHistoryRecord>,
      selectedCameraHistoryRecord: {} as SelectableHistoryRecord,
      isImgErrorSelectedCameraHistoryRecord: false,
      systemHistory: [] as Array<HistoryRecord>,
      updateLocationListIntervalID: -1,
      isComfirmAppStarAndStop: false
    }
  },
  beforeDestroy() {
    // 最新エラー表示のためのタイマーを止める
    window.clearTimeout(this.timeOutId)
    window.clearInterval(this.updateLocationListIntervalID)
  },
  methods: {
    ...flagsMapActions,
    // 日付入力欄に関わる変数群を初期化する関数
    initializeDateInput() {
      const emptyDateFormatted = "----/--/--"
      this.fromCalender = ""
      this.fromDate = ""
      this.fromFormatted = emptyDateFormatted
      this.toFormatted = "～ " + emptyDateFormatted + " ）"
    },
    // カレンダーは当日か過去しか選択出来なくするが、これはその期間の末尾を文字列にて返す
    endDate() {
      return dayjs()
        .utcOffset(this.utcOffsetMinutes)
        .format("YYYY-MM-DD")
    },
    clearHistories() {
      // 異常検出履歴とシステム情報履歴の両方をクリアする
      this.cameraHistory = [] as Array<SelectableHistoryRecord>
      this.systemHistory = [] as Array<HistoryRecord>
    },
    // 検索結果が出た直後に異常検出履歴の1件の選択対象についてリフレッシュする
    refreshSelectedCameraHistoryRecord() {
      if (this.pictureSelectMode === "auto") {
        // 最新エラー表示のモードなら
        if (this.cameraHistory.length > 0) {
          // 履歴が1件以上あるなら先頭要素を選択済みの状態にする(色の変更も含む)
          // ※ここではJSON.parse(JSON.stringify())を用いてコピーしてはいけない
          // ※何故なら代入された左辺の変数を通して右辺の1要素を操作するのが目的だから
          this.selectedCameraHistoryRecord = this.cameraHistory[0]
          this.selectCameraHistoryRecord(this.selectedCameraHistoryRecord)
        } else {
          // 履歴が1件もないなら
          // どれも選択していない状態にする
          this.selectedCameraHistoryRecord = {} as SelectableHistoryRecord
        }
      } else {
        // 手動選択のモードなら
        // どれも選択していない状態にする
        this.selectedCameraHistoryRecord = {} as SelectableHistoryRecord
      }
      // 画像表示でエラー発生していたことをクリアする
      this.isImgErrorSelectedCameraHistoryRecord = false
    },
    // 手動選択の場合の検索用の関数
    async searchHistoriesManually() {
      this.debugLog("searchHistoriesManually:", this.fromFormatted)
      if (this.pictureSelectMode === "auto") {
        // もし"最新エラー表示"のモードだった場合は"手動選択"のモードへ強制的に切り替える
        this.pictureSelectMode = "manual"
      }
      // 日付指定の入力欄が空の場合はエラー
      if (this.fromDate === "") {
        this.debugLog("Error: From Date is not set.")
        this.isErrorOfEmptyFromDate = true
        return undefined
      }
      this.isLoadingOfGetHistories = true
      // 過去データ検索時はテーブルデータをクリアする
      this.clearHistories()
      const result = await this.searchHistoriesCore(true)
      this.isLoadingOfGetHistories = false
      if (result === undefined) {
        // API(x2回)からエラーが1回でも返された場合は、両方のテーブルをクリアする
        this.clearHistories()
        this.isErrorInSearchHistoriesManually = true
      }
      // 異常検出履歴の1件の選択対象についてリフレッシュする
      this.refreshSelectedCameraHistoryRecord()
      return result
    },
    // 最新エラー表示の場合の検索用の関数 (created時の初期化時もこれを用いる)
    async searchHistoriesAutomatic() {
      this.debugLog("searchHistoriesAutomatic:")
      let result
      // タイマーが発火済みかどうかにかかわらずクリアしておく
      window.clearTimeout(this.timeOutId)
      if (this.showDialogOfChangeLocationProfile) {
        // プロファイル切り替え設定のモーダルダイアログを表示中の場合は、
        // 自動での履歴情報取得APIアックセスは実行をスキップする
        this.debugLog("Skip automatic getHistories because dialog is there.")
        result = true
      } else {
        result = await this.searchHistoriesCore(false)
        if (result === undefined) {
          // API(x2回)からエラーが1回でも返された場合は、両方のテーブルをクリアする
          this.clearHistories()
          this.isErrorInSearchHistoriesAutomatic = true
        }
        // 異常検出履歴の1件の選択対象についてリフレッシュする
        this.refreshSelectedCameraHistoryRecord()
        // 最新データ取得日時から指定時間以内にエラーが検出されているかをチェック
        // this.checkRecordTimeOfErrorInHistories()
        // APIアクセスの結果が成功したか失敗したかにかかわらず、周期的に当該関数を呼ぶ
      }
      // ダイアログ表示中かどうかにかかわらず、タイマー処理は継続する
      if (this.pictureSelectMode === "auto") {
        // 最新エラー表示選択時のみ再データ取得する
        this.timeOutId = window.setTimeout(
          this.searchHistoriesAutomatic,
          this.automaticUpdateIntervalMsec
        )
      }
      return result
    },
    // 検索用の共通関数
    async searchHistoriesCore(isManual: boolean) {
      // 日付指定での検索時は、指定内容を取り込む。そうでない場合は無指定にする。
      let startTime = null
      if (isManual) {
        startTime = this.forApiFromFormatted
      }
      this.debugLog("start_time for /histories:", startTime)
      // 最新データ取得日時を更新
      this.lastUpdateTime = dayjs()
        .utcOffset(this.utcOffsetMinutes)
        .format("YYYY/MM/DD HH:mm:ss")
      this.debugLog("lastUpdateTime", this.lastUpdateTime)
      // 2回APIアクセスする内の1回目の直前に、両方のテーブル内容をクリアしておく
      // this.clearHistories()
      const cameraResult = await this.getHistories("camera", startTime)
      if (cameraResult === undefined) {
        this.debugLog("getHistories for camera failed.")
        return undefined
      }
      const systemResult = await this.getHistories("system", startTime)
      if (systemResult === undefined) {
        this.debugLog("getHistories for system failed.")
        return undefined
      }
      return true
    },
    // 異常検出履歴のテーブル表示で1件をクリックされた時の処理
    onClickCameraHistoryEvent(item: SelectableHistoryRecord) {
      if (this.pictureSelectMode === "manual") {
        // 前回の画像表示でエラー発生していたことをクリアする
        this.isImgErrorSelectedCameraHistoryRecord = false
        // 手動選択のモードの場合は、クリックされたことにより選択された状態にする
        this.selectedCameraHistoryRecord = item
        this.selectCameraHistoryRecord(item)
      }
    },
    // 異常検出履歴のテーブル表示の中の1件を選択された状態にする
    selectCameraHistoryRecord(item: SelectableHistoryRecord) {
      //
      const prevItem = this.cameraHistory.find(
        (element: SelectableHistoryRecord) => element.color === "light-blue"
      )
      if (prevItem) this.$delete(prevItem, "color")
      this.$set(item, "color", "light-blue")
      this.debugLog(
        "Select a camera history record:",
        this.selectedCameraHistoryRecord,
        prevItem
      )
    },
    convertIsManualProfileFriendly(is_manual_profile: boolean): string {
      if (is_manual_profile === true) {
        return "あり"
      } else {
        return "なし"
      }
    },
    convertProfileTypeFriendly(profile_type: string): string {
      if (profile_type === "normal") {
        return "対象色範囲"
      } else {
        return "異常色範囲"
      }
    },
    convertProfileValueFriendly(profile_value: FilterSettings): string {
      if (profile_value === undefined) return "No data"
      return (
        "RGB[" +
        profile_value.min.R +
        "," +
        profile_value.min.G +
        "," +
        profile_value.min.B +
        "]-[" +
        profile_value.max.R +
        "," +
        profile_value.max.G +
        "," +
        profile_value.max.B +
        "]"
      )
    },
    // カメラアプリ判定のフラグの値を表示用の文言へ変換
    convertIsCameraRunningFriendly(isRunning: true): string {
      if (isRunning === true) {
        return "稼働中"
      } else {
        return "停止中"
      }
    },
    // カメラアプリ判定のフラグの値を表示用の文言の色へ変換
    convertIsCameraRunningToColor(isRunning: true): string {
      if (isRunning === true) {
        return "green"
      } else {
        return "red"
      }
    },
    // プロファイルマスターメンテナンスのページへ移動
    moveToProfileMasterMaintenance() {
      this.debugLog("moveToProfileMasterMaintenance:")
      router.push("/maintenance")
      return
    },
    // 画像別タブ表示または画像別ウィンドウ表示のボタンを押下した際に
    // 画像を表示可能な状況なのかを確認する
    isReadyToOpenPicture(): boolean {
      if (this.cameraHistory != null && this.cameraHistory.length === 0) {
        // 異常検出履歴が0件
        this.isImgErrorEmptyCameraHistory = true
        return false
      } else if (!this.selectedCameraHistoryRecord.image_path) {
        // 異常検出履歴の内の1件が未選択
        this.isImgErrorNotSelectedCameraHistoryRecord = true
        return false
      } else {
        return true
      }
    },
    // 画像別タブ表示
    openPictureWithNewTab() {
      this.debugLog("openPictureWithNewTab")
      // 画像を表示可能な状況なのかを確認する ※NGなら関数内でフラグを立てる
      if (!this.isReadyToOpenPicture()) {
        return
      }
      const url = this.selectedCameraHistoryRecord.image_path
      const routeData = this.$router.resolve({
        name: "display-image",
        params: { paramsDisplayImage: "ParamsSomeData" },
        query: { queryDisplayImage: url, queryWindoStyle: "new-tab" }
      })
      // 本来のwindow.open()の引数指定方法で呼んでいる
      window.open(routeData.href, "_blank", "noopener")
      return
    },
    // 画像別ウィンドウ表示
    openPictureWithNewWindow() {
      this.debugLog("openPictureWithNewWindow")
      // 画像を表示可能な状況なのかを確認する ※NGなら関数内でフラグを立てる
      if (!this.isReadyToOpenPicture()) {
        return
      }
      const url = this.selectedCameraHistoryRecord.image_path
      const routeData = this.$router.resolve({
        name: "display-image",
        params: { paramsDisplayImage: "ParamsSomeData" },
        query: { queryDisplayImage: url, queryWindoStyle: "new-window" }
      })
      // 本来のwindow.open()の引数指定方法ではない呼び方
      // (「_blank」は本来は第2引数に指定)なのに、何故か新規ウィンドウで開いてくれる
      window.open(routeData.href, "target", "_blank,noopener")
      return
    },
    // 画像表示でエラー発生時の処理
    onImgError() {
      this.debugLog(
        "Error occurred while displaying image:",
        this.selectedCameraHistoryRecord.image_path
      )
      // 画像表示でエラー発生したことを警告表示する
      this.isImgErrorSelectedCameraHistoryRecord = true
    },
    // 「/locations」APIをアクセス
    // 取得したデータをコンポーネント内の変数へ格納する
    async getLocations() {
      this.isLoadingOfGetLocations = true
      return await this.webApiModel
        .get("/locations")
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /locations:",
            response.result,
            response.message
          )
          // this.debugLog("locations:", response)
          const tmpLocations: Array<LocationRecord> = []
          if (response.records != null && response.records.length > 0) {
            response.records.forEach((receiveData: LocationRecord) => {
              // そのまま採用して配列へ追記
              tmpLocations.push(receiveData)
            })
          }
          // 以下はコピー処理「this.locations = tmpLocations」を意図している
          this.locations = JSON.parse(JSON.stringify(tmpLocations))
          this.debugLog("this.locations", this.locations)
          this.isLoadingOfGetLocations = false
          return true
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /locations:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          this.isLoadingOfGetLocations = false
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          return undefined
        })
    },
    async getLocationsBackground() {
      return await this.webApiModel
        .get("/locations")
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /locations:",
            response.result,
            response.message
          )
          // this.debugLog("locations:", response)
          const tmpLocations: Array<LocationRecord> = []
          if (response.records != null && response.records.length > 0) {
            response.records.forEach((receiveData: LocationRecord) => {
              // そのまま採用して配列へ追記
              tmpLocations.push(receiveData)
            })
          }
          // 以下はコピー処理「this.locations = tmpLocations」を意図している
          this.locations = JSON.parse(JSON.stringify(tmpLocations))
          this.selectedLocation = this.locations.filter(
            (locationData: LocationRecord) => {
              return (
                this.selectedLocation.location_id === locationData.location_id
              )
            }
          )[0]
          // 選択している設備名のカメラアプリ判定の稼働状況を取り込み
          this.isCameraRunning = this.selectedLocation.is_running_remote
          return true
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /locations:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          this.isLoadingOfGetLocations = false
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          return undefined
        })
    },
    // 「/profiles」APIをアクセス
    // 取得したデータをコンポーネント内の変数へ格納する
    async getProfiles() {
      this.isLoadingOfGetProfiles = true
      return await this.webApiModel
        .get("/profiles")
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /profiles:",
            response.result,
            response.message
          )
          // this.debugLog("profiles:", response)
          const tmpProfiles: Array<ProfileRecord> = []
          if (response.records != null && response.records.length > 0) {
            response.records.forEach((receiveData: ProfileRecord) => {
              // そのまま採用して配列へ追記
              tmpProfiles.push(receiveData)
            })
          }
          // this.debugLog("tmpProfiles", tmpProfiles)
          // 以下はコピー処理「this.profiles = tmpProfiles」を意図している
          this.profiles = JSON.parse(JSON.stringify(tmpProfiles))
          this.debugLog("this.profiles", this.profiles)
          this.isLoadingOfGetProfiles = false
          return true
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /profiles:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          this.isLoadingOfGetProfiles = false
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          return undefined
        })
    },
    // 「/histories」APIをアクセス
    // 取得したデータをコンポーネント内の変数へ格納する
    async getHistories(recordType: string, startTime: string) {
      let queryParams
      if (startTime != null && startTime.length > 0) {
        // 引数のstartTimeに値が指定されていた場合
        queryParams = {
          queryStringParameters: {
            location_id: this.locationId,
            record_type: recordType,
            start_time: startTime
          }
        }
      } else {
        // 引数のstartTimeに値が指定されていなかった場合
        queryParams = {
          queryStringParameters: {
            location_id: this.locationId,
            record_type: recordType
          }
        }
      }
      return await this.webApiModel
        .get("/histories", queryParams)
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /histories:",
            response.result,
            response.message
          )
          // this.debugLog("response:", response)
          if (recordType === "camera") {
            // 異常検出履歴のテーブル表示用の配列を作成する
            const tmpHistories: Array<SelectableHistoryRecord> = []
            if (response.records != null && response.records.length > 0) {
              response.records.forEach((receiveData: HistoryRecord) => {
                if (
                  receiveData.record_time &&
                  receiveData.message &&
                  receiveData.image_path
                ) {
                  // 3つの要素の全てが欠落しておらず正常の場合に限定でデータを取り込む
                  const copyRecord: SelectableHistoryRecord = {
                    record_time: dayjs
                      .utc(receiveData.record_time)
                      .utcOffset(this.utcOffsetMinutes)
                      .format("YYYY/MM/DD HH:mm:ss"),
                    message: receiveData.message,
                    image_path: receiveData.image_path
                  }
                  // 署名付きURLの生値をチェックしたい場合はログ出力を実行
                  // this.debugLog("copyRecord", copyRecord)
                  tmpHistories.push(copyRecord)
                }
              })
            }
            // 取得したデータの１件目の時間・メッセージが異なる場合のみデータ更新を行う
            if (this.cameraHistory.length > 0) {
              if (
                this.cameraHistory[0].record_time !==
                  tmpHistories[0].record_time ||
                this.cameraHistory[0].message !== tmpHistories[0].message
              ) {
                this.cameraHistory = JSON.parse(JSON.stringify(tmpHistories))
                this.checkRecordTimeOfErrorInHistories()
              }
            } else {
              this.cameraHistory = JSON.parse(JSON.stringify(tmpHistories))
              this.checkRecordTimeOfErrorInHistories()
            }
            // 以下はコピー処理「this.cameraHistory = tmpHistories」を意図している
            // this.cameraHistory = JSON.parse(JSON.stringify(tmpHistories))
            this.debugLog("this.cameraHistory", this.cameraHistory)
            return true
          } else {
            // 引数のrecordTypeが'camera'でなかった場合は'system'を指定されたものとして扱う
            // システム情報履歴のテーブル表示用の配列を作成する
            const tmpHistories: Array<HistoryRecord> = []
            if (response.records != null && response.records.length > 0) {
              response.records.forEach((receiveData: HistoryRecord) => {
                if (receiveData.record_time && receiveData.message) {
                  // 2つの要素の全てが欠落しておらず正常の場合に限定でデータを取り込む
                  const copyRecord: HistoryRecord = {
                    record_time: dayjs
                      .utc(receiveData.record_time)
                      .utcOffset(this.utcOffsetMinutes)
                      .format("YYYY/MM/DD HH:mm:ss"),
                    message: receiveData.message,
                    record_status: receiveData.record_status
                  }
                  tmpHistories.push(copyRecord)
                }
              })
            }
            if (this.systemHistory.length > 0) {
              if (
                this.systemHistory[0].record_time !==
                  tmpHistories[0].record_time ||
                this.systemHistory[0].message !== tmpHistories[0].message
              ) {
                this.systemHistory = JSON.parse(JSON.stringify(tmpHistories))
                this.checkRecordTimeOfErrorInHistories()
              }
            } else {
              this.systemHistory = JSON.parse(JSON.stringify(tmpHistories))
              this.checkRecordTimeOfErrorInHistories()
            }
            this.debugLog("this.systemHistory", this.systemHistory)
            return true
          }
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /histories:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          return undefined
        })
    },
    // 「/stop-restart」APIをアクセス
    // 指定した設備名のカメラアプリ判定のスタートまたはストップを実行する
    async cameraAppStartAndStop() {
      this.isComfirmAppStarAndStop = false
      this.isCameraRunning = !this.isCameraRunning
      const payload = {
        body: {
          location_id: this.locationId,
          is_running_remote: this.isCameraRunning
        }
      }
      this.debugLog("post to /stop-restart...")
      this.isLoadingOfCameraAppStartAndStop = true
      return await this.webApiModel
        .post("/stop-restart", payload)
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /stop-restart:",
            response.result,
            response.message
          )
          this.isLoadingOfCameraAppStartAndStop = false
          return true
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /stop-restart:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          this.isLoadingOfCameraAppStartAndStop = false
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          // カメラアプリ判定ストップ⇔スタートの切り替えに失敗した旨のダイアログを表示する
          this.isErrorInCameraAppStartAndStop = true
          return undefined
        })
    },
    // 「/location」APIをアクセス
    // 当該の設備名についてプロファイル切り替えを実行する
    async changeLocationProfile() {
      const payload = {
        body: {
          // 「設備名」メニューの選択状態に基く↓
          location_id: this.selectedLocation.location_id,
          camera_filter_schema_remote: this.selectedLocation
            .camera_filter_schema_remote,
          // 「プロファイル名の切り替え」メニューの選択状態に基く↓
          profile_id: this.selectedProfile.profile_id,
          profile_type: this.currentFilterType,
          // 「両端除外設定」の入力欄の状態に基く↓
          left_exclusion_mm: this.currentLeftExclusionMm,
          right_exclusion_mm: this.currentRightExclusionMm,
          // 「対象色範囲」または「異常色範囲」の入力欄の状態に基く↓
          profile_value: JSON.parse(JSON.stringify(this.currentFilterSettings))
        }
      }
      this.debugLog("put to /location...")
      this.isLoadingOfChangeLocationProfile = true
      return await this.webApiModel
        .put("/location", payload)
        .then((response: any): any => {
          this.debugLog(
            "Succeeded in /location:",
            response.result,
            response.message
          )
          this.isLoadingOfChangeLocationProfile = false
          // responseのrecordsの配列(1件固定)の1件目を戻り値として返す
          return response.records[0]
        })
        .catch((err: any): undefined => {
          this.debugLog("Failed on /location:")
          if (err.response != null) {
            this.debugLog("Error response:", err.response)
          }
          if (err.message != null) {
            this.debugLog("Error message:", err.message)
          }
          this.isLoadingOfChangeLocationProfile = false
          // セッションタイムアウトでないか確認する
          this.checkSessionTimeout(err)
          return undefined
        })
    },
    // プロファイル切り替え設定のモーダルダイアログの表示を準備する
    // 単にダイアログの表示／非表示を切り替えるだけではなく、
    // 「プロファイル一覧取得」APIを呼んでから表示する必要がある
    async prepareDialogOfChangeLocationProfile() {
      // プロファイル一覧取得APIの結果を格納
      const result = await this.getProfiles()
      if (result === undefined) {
        this.debugLog("getProfiles failed.")
        // プロファイル一覧取得に失敗した旨のモーダルダイアログを表示する
        // そこで「OK」ボタンを押すと、プロファイル切り替え設定のモーダルダイアログの
        // 表示は行わず、メインページへ戻る
        this.isErrorInPrepareDialogOfChangeLocationProfile = true
      } else {
        // 一旦プロファイルとして何も選択していない扱いにする
        this.selectedProfile = {} as ProfileRecord
        // この設備名に紐付いているプロファイルに該当するプロファイルIDを探して、
        // そのプロファイルを選択している扱いへと更新する
        for (const profileData of this.profiles) {
          if (
            profileData.profile_id === this.selectedLocation.target_profile_id
          ) {
            this.selectedProfile = JSON.parse(JSON.stringify(profileData))
            break
          }
        }
        // 注(仕様相談結果)：合致するプロファイルが一覧の中に見付からなかった場合の考慮は不要
        if (Object.keys(this.selectedProfile).length === 0) {
          // 見付からなかった場合(バグの可能性もある)はログ出力しておく
          this.debugLog(
            "Profile ID of this location not found:",
            this.selectedLocation.target_profile_id
          )
        }
        // プロファイルを選択した状態にするものの、
        // このモーダルダイアログで設定変更可能な情報は、
        // この設備名に基く情報をデフォルトとする
        this.currentFilterSettings = JSON.parse(
          JSON.stringify(this.selectedLocation.profile_value)
        )
        this.currentLeftExclusionMm = this.selectedLocation.left_exclusion_mm
        this.currentRightExclusionMm = this.selectedLocation.right_exclusion_mm
        // プロファイルのタイプはこのモーダルダイアログにて直接は変更可能ではないが、
        // プロファイル名を変更することに付随して間接的に変更が可能である
        // この項目も、この設備名に基く情報をデフォルトとする
        this.currentFilterType = this.selectedLocation.profile_type
        this.debugLog(
          "currentFilterSettings, etc. from location:",
          this.selectedLocation.target_profile_id,
          this.currentFilterSettings,
          this.currentLeftExclusionMm,
          this.currentRightExclusionMm,
          this.currentFilterType
        )
        // ダイアログを表示する
        this.showDialogOfChangeLocationProfile = true
      }
    },
    changeInputFormForNewSelectedProfile() {
      this.debugLog("Selected Profile:", this.selectedProfile)
      // 選択したプロファイルの内容に基き、各入力欄へデフォルト値を流し込む
      this.currentFilterSettings = JSON.parse(
        JSON.stringify(this.selectedProfile.profile_value)
      )
      this.currentLeftExclusionMm = this.selectedProfile.left_exclusion_mm
      this.currentRightExclusionMm = this.selectedProfile.right_exclusion_mm
      // プロファイルのタイプは入力欄にはないが、
      // どの入力欄を編集可能にするかに関わるため、
      // 選択したプロファイルの内容に基き、デフォルト値を流し込む
      this.currentFilterType = this.selectedProfile.profile_type
      this.debugLog(
        "currentFilterSettings, etc. from profile defaults:",
        this.selectedProfile.profile_id,
        this.currentFilterSettings,
        this.currentLeftExclusionMm,
        this.currentRightExclusionMm,
        this.currentFilterType
      )
    },
    async submitChangeLocationProfile() {
      if (!this.isValidInputFormForChangeLocationProfile) {
        this.isErrorOfInputChangeLocationProfile = true
        this.debugLog("Found error in input for ChangeLocationProfile")
      } else {
        this.debugLog("Go ahead ChangeLocationProfile")
        const record = await this.changeLocationProfile()
        if (record === undefined) {
          this.debugLog("changeLocationProfile failed.")
          // プロファイル切り替え(場所ごと)に失敗した旨のダイアログを表示する
          this.isErrorInChangeLocationProfile = true
          return
        }
        this.debugLog("response record:", record)
        // レスポンス内容にて当該設備名の情報を上書きする
        this.selectedLocation.left_exclusion_mm = record.left_exclusion_mm
        this.selectedLocation.right_exclusion_mm = record.right_exclusion_mm
        this.selectedLocation.is_manual_profile = record.is_manual_profile
        this.selectedLocation.camera_filter_schema_remote =
          record.camera_filter_schema_remote
        this.selectedLocation.target_profile_id = record.target_profile_id
        this.selectedLocation.profile_type = record.profile_type
        this.selectedLocation.profile_value = JSON.parse(
          JSON.stringify(record.profile_value)
        )
        // レスポンス情報にプロファイルIDは載っているがプロファイル名は載っていないため、
        // 過去に取得したプロファイル一覧情報からプロファイルIDが一致するものを探して
        // プロファイル名を特定する
        for (const profileData of this.profiles) {
          if (
            profileData.profile_id === this.selectedLocation.target_profile_id
          ) {
            this.selectedLocation.profile_name = profileData.profile_name
            break
          }
        }
        // 成功時はプロファイル切り替え設定のモーダルダイアログを閉じる
        this.showDialogOfChangeLocationProfile = false
      }
    },
    // 設備名の選択に変化があった場合の処理
    updateWhenSelectedLocationChanged() {
      if (this.selectedLocation.is_running_remote != null) {
        // 選択している設備名のカメラアプリ判定の稼働状況を取り込み
        this.isCameraRunning = this.selectedLocation.is_running_remote
        this.debugLog("is_running_remote:", this.isCameraRunning)
      }
      if (this.selectedLocation.location_id != null) {
        // 選択している設備名のlocation_idを、検索処理用に保存しておく
        this.locationId = this.selectedLocation.location_id
        this.debugLog("this.locationId", this.locationId)
        // 選択変更前の履歴情報の内容を表示している状態であるため、
        // 両方のテーブル内容をクリアしておく
        this.clearHistories()
        // 異常検出履歴の1件の選択対象についてリフレッシュする
        this.refreshSelectedCameraHistoryRecord()
        // 手動選択の場合の検索でAPIがエラーを返した場合のフラグをクリアしておく
        this.isErrorInSearchHistoriesManually = false
        // 最新エラー表示の場合の検索でAPIがエラーを返した場合のフラグをクリアしておく
        this.isErrorInSearchHistoriesAutomatic = false
        // 最新データ取得日時から指定時間以内にエラーが検出されているかを
        // チェックした結果のフラグをクリアしておく
        this.foundRecentErrorInHistories = false
      }
    },
    // ヘッダコンポーネントから設備名の選択に変化があった通知を受けた場合の処理
    onChangeSelectedLocationByMainHeader(selected: LocationRecord) {
      // 引数にて選択変更後の値をヘッダコンポーネントから受け取る
      this.debugLog("Changed selected location by MainHeader.", selected)
      // 値のコピーではなく参照渡しの状態にする
      // 以降、this.selectedLocationの内容を変更すると、
      // 配列this.locations内の該当要素も間接的に変更されてしまう点に注意のこと
      this.selectedLocation = selected
      this.updateWhenSelectedLocationChanged()
    },
    // ログアウト処理 ※ログアウトしてログイン画面へ遷移する
    async signout(): Promise<void> {
      try {
        await Auth.signOut()
        router.push("/signin")
      } catch (err) {
        console.error(err)
      }
    },
    // セッションタイムアウトなのかを判定し、その場合には強制ログアウトする処理
    async checkSessionTimeout(error: any) {
      if (
        error.response == null &&
        error.message != null &&
        error.message === "Refresh Token has expired"
      ) {
        // セッションタイムアウトの場合には、即時ログアウトする
        this.debugLog("Signout becase of session timeout.")
        this.signout()
      } else {
        // セッションタイムアウトでない場合は何もしない
      }
    },
    // 最新データ取得日時から指定時間以内にエラーが検出されているかをチェックする
    // ※履歴情報の自動更新の結果が出た直後のタイミングで、
    //   異常検出履歴の配列とシステム情報履歴の配列を上から順に辿り、
    //   前者の「最初の1件」と後者の「タイプがerrorである最初の1件」をピックアップし、
    //   その日時と、検索を開始する時に取得した日時とを比べて、
    //   差分が指定時間以内であるか？をチェックして結果をフラグに格納する処理
    //   (日時順で並んでいるため、最初の1件だけを処理すればよい)
    checkRecordTimeOfErrorInHistories() {
      // 先ずフラグを落としておく
      this.foundRecentErrorInHistories = false
      // 同エラー表示で異常検出履歴の方かシステム情報履歴の方かの表記部分をクリア
      this.recordTypeOfFoundRecentErrorInHistories = ""
      // 同エラー表示の日時表記部分をクリア
      this.recordTimeOfFoundRecentErrorInHistories = ""
      this.recentErrorMessage = ""
      // 検索を開始する時に取得した日時を比較可能な変数へ変換する
      const searchDateTime = dayjs(this.lastUpdateTime).utcOffset(
        this.utcOffsetMinutes
      )
      // 最新データ取得日時から指定時間以内にエラーが検出されているかを
      // チェックする際の秒数
      const diffMaxSec = this.diffSecondsOfcheckRecordTimeOfErrorInHistories
      // 異常検出履歴の配列を上から順に辿り、
      // 「最初の1件」をピックアップ ※異常検出履歴は全件がエラー扱い
      for (const historyData of this.cameraHistory) {
        // 見付けた1件について時刻を比較可能な変数へ変換する
        const errorDateTime = dayjs(historyData.record_time).utcOffset(
          this.utcOffsetMinutes
        )
        // 差分を秒単位で算出する ※指定時間の環境変数が秒単位であるため
        const diffSec = searchDateTime.diff(errorDateTime, "second")
        if (diffSec <= diffMaxSec) {
          this.debugLog(
            "Found the first error camera record within (sec), NG:",
            diffSec,
            historyData.record_time
          )
          // NGだったのでフラグを立てる
          this.foundRecentErrorInHistories = true
          // 表示で異常検出履歴の方かシステム情報履歴の方かの表記部分を設定
          this.recordTypeOfFoundRecentErrorInHistories = "異常検出履歴："
          // 表示の日時表記部分を設定
          this.recordTimeOfFoundRecentErrorInHistories = historyData.record_time
          this.recentErrorMessage =
            this.recordTypeOfFoundRecentErrorInHistories +
            this.recordTimeOfFoundRecentErrorInHistories +
            "<br/>"
        } else {
          this.debugLog(
            "Found the first error camera record within (sec), OK:",
            diffSec
          )
        }
        // 最初の1件を処理したらループを終了
        break
      }
      // システム情報履歴の配列を上から順に辿り、
      // 「タイプがerrorである最初の1件」をピックアップ
      for (const historyData of this.systemHistory) {
        if (historyData.record_status === "error") {
          // 見付けた1件について時刻を比較可能な変数へ変換する
          const errorDateTime = dayjs(historyData.record_time).utcOffset(
            this.utcOffsetMinutes
          )
          // 差分を秒単位で算出する ※指定時間の環境変数が秒単位であるため
          const diffSec = searchDateTime.diff(errorDateTime, "second")
          if (diffSec <= diffMaxSec) {
            this.debugLog(
              "Found the first error system record within (sec), NG:",
              diffSec,
              historyData.record_time
            )
            // NGだったのでフラグを立てる
            this.foundRecentErrorInHistories = true
            // 表示で異常検出履歴の方かシステム情報履歴の方かの表記部分を設定
            this.recordTypeOfFoundRecentErrorInHistories = "システム情報履歴："
            // 表示の日時表記部分を設定
            this.recordTimeOfFoundRecentErrorInHistories =
              historyData.record_time
            this.recentErrorMessage =
              this.recentErrorMessage +
              this.recordTypeOfFoundRecentErrorInHistories +
              this.recordTimeOfFoundRecentErrorInHistories
          } else {
            this.debugLog(
              "Found the first error system record within (sec), OK:",
              diffSec
            )
          }
          // 最初の1件を処理したらループを終了
          break
        }
      }
    },
    // デバッグ中のためのconsole.log()へのwrapper関数
    // ⇒リリース時は関数内をコメントアウトすること
    debugLog(arg0: any, ...args: any): void {
      // console.log(arg0, ...args)
    },
    filterByLocationID(locationID: string) {
      return this.locations.filter((locationData: LocationRecord) => {
        return locationData.location_id === locationID
      })
    }
  },
  async created() {
    // 予めdayjsのutcプラグインを有効化しておく
    dayjs.extend(utc)
    // APIアクセス用のライブラリ
    this.webApiModel = new WebApiModel()
    this.debugLog(
      "breakpoint xy:",
      this.$vuetify.breakpoint.width,
      this.$vuetify.breakpoint.height
    )
    // 最新データ取得日時から指定時間以内にエラーが検出されているかを
    // チェックしてNGだった場合のエラー表示の文言を設定する。
    const recentPeriodMinute = Math.round(
      this.diffSecondsOfcheckRecordTimeOfErrorInHistories / 60
    )
    this.messageForFoundRecentErrorInHistories =
      "最新データ取得日時から" +
      String(recentPeriodMinute) +
      "分以内に異常が検出されています。"
    this.debugLog(
      "Automatic Update Interval(msec), and Check Recent Error Interval(sec):",
      this.automaticUpdateIntervalMsec,
      this.diffSecondsOfcheckRecordTimeOfErrorInHistories
    )
    // 日付入力欄に関わる変数群を初期化
    this.initializeDateInput()
    // 場所一覧取得APIの結果を格納
    this.selectedLocation = {} as LocationRecord
    const resultGetLocations = await this.getLocations()
    if (resultGetLocations === undefined) {
      this.debugLog("getLocations Failed when created.")
      // 場所一覧取得に失敗した旨のダイアログを表示する
      this.isErrorInGetLocationsWhenCreated = true
      // 続行不可のため当該関数を抜ける
      return
    }
    // 場所一覧の内の先頭要素をデフォルトで選択する
    // 値のコピーではなく参照渡しの状態にする
    // 以降、this.selectedLocationの内容を変更すると、
    // 配列this.locations内の該当要素も間接的に変更されてしまう点に注意のこと
    const selectedLocationID = localStorage.getItem("location_id")
    if (selectedLocationID) {
      if (this.filterByLocationID(selectedLocationID).length == 1) {
        this.selectedLocation = this.filterByLocationID(selectedLocationID)[0]
      }
    } else {
      // if: ローカルストレージに値がない場合は配列の最初の要素を利用する
      this.selectedLocation = this.locations[0]
    }

    // 設備名の選択の変更を反映
    this.updateWhenSelectedLocationChanged()
    // 履歴データ一覧を取得(初回)
    this.isLoadingOfGetHistories = true
    const result = await this.searchHistoriesAutomatic()
    this.isLoadingOfGetHistories = false
    this.debugLog("searchHistoriesAutomatic result:", result)
    this.pictureSelectMode = "auto"
    // 設備情報定期更新処理を実行
    this.debugLog("start equipment information periodic update processing")
    this.updateLocationListIntervalID = window.setInterval(
      this.getLocationsBackground,
      this.automaticUpdateIntervalMsec,
      false
    )
  },
  watch: {
    // 日付指定の入力欄に変化があれば、
    // 表示用の日時文字列(検索の開始日と終了時)と、API呼び出し用の日時文字列を作成する
    fromDate: function() {
      if (this.fromDate === "") {
        // 空文字列に変更された場合は、関数initializeDateInput()の呼び出しにより
        // 変更されたと見込まれる。その関数では、他の日付指定に関わる変数群についても
        // 初期化を実施するため、当該watch処理では何もしなくてよい。
      } else {
        this.fromFormatted = dayjs(this.fromDate).format("YYYY/MM/DD")
        // 日付指定の入力欄の日付に対して3日後でなく2日後を終わり側として表示する
        // ※実際の検索処理は、始まり側の日付に対してAPI側が3日分を計算して実施する
        const tmpToFormatted = dayjs(this.fromDate)
          .add(2, "days")
          .format("YYYY/MM/DD")
        this.toFormatted = "～ " + tmpToFormatted + " ）"
        // 検索の開始日時をUTC指定にてISO 8601形式にて作成し、
        // 秒の小数点以下3桁を削除してからを格納
        this.forApiFromFormatted = dayjs(this.fromFormatted)
          .utcOffset(this.utcOffsetMinutes)
          .toISOString()
          .replace(/\....Z$/, "Z")
        this.debugLog("this.forApiFromFormatted:", this.forApiFromFormatted)
      }
    },
    // エラー画像選択モードの選択に変化があった場合の処理
    pictureSelectMode: function() {
      if (this.pictureSelectMode === "auto") {
        // "手動選択"から"最新エラー表示"へ切り替えた場合
        // 日付入力欄に関わる変数群を初期化
        this.initializeDateInput()
        // モードを切り替えたら直ちに検索を実行する
        this.searchHistoriesAutomatic()
        // 検索の結果がまもなく得られるので、このタイミングで
        // 異常検出履歴のテーブルの先頭要素を選択済みの状態にする処理は不要
      } else if (this.pictureSelectMode == "manual") {
        // "最新エラー表示"から"手動選択"へ切り替えた場合
        // 最新エラー表示の検索のためのタイマーを止める
        window.clearTimeout(this.timeOutId)
      }
    },
    // TODO: コメントアウト
    // // 設備名の選択に変化があった場合の処理
    // selectedLocation: function() {
    //   this.updateWhenSelectedLocationChanged()
    // },
    // ローディング表示用のフラグの集合結果に変化があった場合の処理
    isLoading: function() {
      // falseからtrueへ変化した時
      if (this.isLoading) {
        this.setOverlay(true)
      } else {
        // trueからfalseへ変化した時
        this.setOverlay(false)
      }
    }
  },
  computed: {
    rgbRules() {
      const errMessage = "有効範囲：0～255"
      const rules = [
        (v: number) => {
          if (v == null) {
            return true
          }
          return (v >= 0 && v <= 255) || errMessage
        },
        (v: any) => {
          return typeof v === "number" || errMessage
        }
      ]
      return rules
    },
    exclusionMmRules() {
      const errMessage = "有効範囲：0～100"
      const rules = [
        (v: number) => {
          if (v == null) {
            return true
          }
          return (v >= 0 && v <= 100) || errMessage
        },
        (v: any) => {
          return typeof v === "number" || errMessage
        }
      ]
      return rules
    },
    isCurrentFilterTypeNormal(): boolean {
      if (this.currentFilterType === "normal") {
        return true && this.isSetCurrentFilterSettings
      } else {
        return false && this.isSetCurrentFilterSettings
      }
    },
    isCurrentFilterTypeAbormal(): boolean {
      if (this.currentFilterType === "abnormal") {
        return true && this.isSetCurrentFilterSettings
      } else {
        return false && this.isSetCurrentFilterSettings
      }
    },
    isSetCurrentFilterSettings(): boolean {
      if (
        this.currentFilterSettings != null &&
        this.currentFilterSettings.min != null &&
        this.currentFilterSettings.max != null
      ) {
        return true
      } else {
        return false
      }
    },
    isLoading(): boolean {
      return (
        this.isLoadingOfGetLocations ||
        this.isLoadingOfGetProfiles ||
        this.isLoadingOfGetHistories ||
        this.isLoadingOfCameraAppStartAndStop
      )
    }
  }
})
