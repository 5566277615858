

















































































































































































































































import Vue from "vue"
import router from "@/router"
import { SignInComponentState } from "@/types"
import { SignUpParam, ResetPasswordParam } from "@/types"
import { Auth } from "aws-amplify"
import { SignUpParams } from "@aws-amplify/auth/lib/types/Auth"
import LoginHeader from "@/components/LoginHeader.vue"
import { flagsNamespacedHelper } from "@/stores/flags"

// Vuex store helpers
const flagsMapActions = flagsNamespacedHelper.mapActions(["setOverlay"])

const defaultSignupParam: SignUpParam = {
  mailAddress: "",
  userName: "",
  password: ""
}

const defaultResetPasswordParam: ResetPasswordParam = {
  userName: "",
  password: "",
  code: ""
}

export default Vue.extend({
  components: { LoginHeader },
  data(): SignInComponentState {
    return {
      signinForm: "signin",
      errorMessages: [],
      userName: "",
      password: "",
      signupParam: defaultSignupParam,
      resetPasswordParam: defaultResetPasswordParam,
      authenticationCode: "",
      isSameEmailAndLoginName: false,
      signinValid: true,
      signupValid: true,
      nameRules: [(v: any) => !!v || "ログイン名は必須です"],
      passwordRules: [(v: any) => !!v || "パスワードは必須です"],
      emailRules: [(v: any) => !!v || "メールアドレスは必須です"]
    }
  },
  async created() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        // すでにログイン済みの場合はホーム画面へ遷移する
        router.push("/home")
      })
      .catch((error: any) => {
        console.error(error)
      })
  },
  methods: {
    ...flagsMapActions,
    // サインイン処理
    async signIn(): Promise<void> {
      if (this.signinValidate()) {
        this.setOverlay(true)
        try {
          await Auth.signIn(this.userName, this.password)
          router.push("/home")
        } catch (err) {
          this.setOverlay(false)
          console.error(err)
          this.checkErrorCode(err.code)
        }
      }
    },
    // サインアップ処理(ユーザー作成)
    async signUp(): Promise<void> {
      if (this.signupValidate()) {
        try {
          const params: SignUpParams = {
            username: this.signupParam.userName,
            password: this.signupParam.password,
            attributes: {
              email: this.signupParam.mailAddress
            }
          }
          if (this.isSameEmailAndLoginName) {
            params.username = this.signupParam.mailAddress
            delete params.attributes
          }
          console.log(params)
          await Auth.signUp(params)
          this.signinForm = "signin"
          this.signupParam = defaultSignupParam
        } catch (err) {
          console.error(err)
          this.checkErrorCode(err.code)
        }
      }
    },
    // パスワードリセット用のコード送信
    async forgotPassword(): Promise<void> {
      try {
        await Auth.forgotPassword(this.resetPasswordParam.userName)
        this.signinForm = "submitCode"
      } catch (err) {
        console.error(err)
        this.checkErrorCode(err.code)
      }
    },
    // パスワードリセット
    async resetPassword(): Promise<void> {
      try {
        await Auth.forgotPasswordSubmit(
          this.resetPasswordParam.userName,
          this.resetPasswordParam.code,
          this.resetPasswordParam.password
        )
        this.signinForm = "signin"
        this.resetPasswordParam = defaultResetPasswordParam
      } catch (err) {
        console.error(err)
        this.checkErrorCode(err.code)
      }
    },
    // cognitoから返ってきたエラーコードでメッセージを切り替える
    checkErrorCode(code: string): void {
      const self: SignInComponentState = this
      self.errorMessages = []
      switch (code) {
        case "UsernameExistsException":
          self.errorMessages.push("すでに登録済みのユーザー名です")
          break
        case "InvalidPasswordException":
          self.errorMessages.push(
            "パスワードポリシー(大文字/小文字/数字を含む8文字以上)を満たしていません"
          )
          break
        case "UserNotFoundException":
          this.errorMessages.push("存在しないユーザーです")
          break
        case "NotAuthorizedException":
          this.errorMessages.push("パスワードが間違っています")
          break
        case "UserNotConfirmedException":
          this.errorMessages.push("ユーザーの有効化をしてください")
          break
        case "ValidationFailure":
          this.errorMessages.push("認証コードが違います")
          break
        case "InvalidParameterException":
          this.errorMessages.push("入力内容が正しくありません")
          break
        case "DomainNotAllowed":
          this.errorMessages.push("許可されていないドメインです")
          break
        default:
          self.errorMessages.push("失敗しました")
          break
      }
      setTimeout(() => {
        self.errorMessages = []
      }, 5000)
    },
    signinValidate() {
      return (this.$refs.signinForm as any).validate()
    },
    signupValidate() {
      return (this.$refs.signupForm as any).validate()
    },
    resetValidate() {
      ;(this.$refs.signinForm as any).reset()
      ;(this.$refs.signupForm as any).reset()
    },
    createUserName() {
      this.signupParam.userName = this.signupParam.mailAddress.split("@")[0]
    },
    back(
      page:
        | "resetPassword"
        | "signin"
        | "signup"
        | "submitCode"
        | "authenticationCode"
    ) {
      this.signinForm = page
      this.errorMessages = []
      this.signupParam = defaultSignupParam
    }
  }
})
