import Vue from "vue"
import Router from "vue-router"
import Home from "./views/Home.vue"
import SignIn from "./views/SignIn.vue"
import Maintenance from "./views/MasterMaintenance.vue"
import DisplayImage from "@/components/DisplayImage.vue"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "signin",
      component: SignIn
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: { requiredAuth: true }
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance,
      meta: { requiredAuth: true }
    },
    {
      path: "/display-image",
      name: "display-image",
      component: DisplayImage
    },
    {
      path: "*",
      redirect: {
        name: "signin"
      }
    }
  ]
})
